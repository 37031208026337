import {Injectable} from "@angular/core";
import {_Service} from "./_service";
import {GenericSetting} from "../../model";

@Injectable({providedIn: 'root'})
export class GenericSettingService extends _Service<GenericSetting> {
  constructor() {
    super('api/generic-setting');
  }

  byName = async (name: string) =>
    this.http.get<GenericSetting>(this.prefix + '/by-name/' + encodeURIComponent(name));

  getAdminAvailability = async (): Promise<GenericSetting> => {
    try {
      return await this.http.get<GenericSetting>(this.prefix + '/by-name/admin-availability');
    } catch (error) {
      return {value: 'offline'} as GenericSetting;
    }
  }
}
